.field-validation-message{
  /* margin-top: 10px; */
  /* line-height: 29px; */
  margin-bottom: 0px;
  display: inline-block;
  color: rgba(232, 17, 35, 1);
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:100%;
}
.validation-field{
  margin-top: 20px;
  width:100%;
}
.field-validation-message:first-child{
  margin-left: 0;
}

.field-validation-message:first-of-type{
  margin-left: 0;
}

.alert{
  color: rgba(184, 14, 28, 1);
  vertical-align: bottom;
  font-size: 20px;
}
.upload-section{
  padding-top: 15px;
}
.submit-title{
height: 24px;
width: 560px;
color: rgba(0,0,0,0.87);
font-family: Montserrat;
font-size: 20px;
font-weight: 600;
letter-spacing: 0.25px;
line-height: 24px;
}

.submit-button {
height: 16px;
width: 174px;
color: #FFFFFF;
font-family: "Open Sans";
font-size: 13.7px;
font-weight: 600;
letter-spacing: 1.25px;
line-height: 16px;
text-align: center;
margin-top: 10px;
margin-right: 64px;
float: right;
padding-bottom: 30px;
}

button[disabled].buttons-outlined-with-icon-enable {
color: rgba(0,0,0,0.38);
box-sizing: border-box ;
background-color: #E4E4E4;
border: none;
}

.buttons-outlined-with-icon-enable {
height: 37px;
width: 240px;
font-family: "Open Sans";
font-size: 14px;
font-weight: 600;
letter-spacing: 1.25px;
line-height: 16px;
text-align: center;
color: #FFFFFF;
box-sizing: border-box;
border: 1px solid #414C82;
background-color: #414C82;
}

.submit-button-text{
margin-left: 10px;
}

.popup-header-divider{
opacity: 0.12;
height: 2px;
background-color: rgba(0,0,0,0.87);
margin-left: -33px;
margin-top: -2px;
}

.submit-popup-body {
height: 56px;
width: 352px;
color:black;
font-family: "Open Sans";
font-size: 15.8px;
letter-spacing: 0.5px;
line-height: 28px;
margin-top: 27px;
margin-left: 27px;
}

.my-custom-modal-css .modal-wrapper {
height: 180px;
width: 400px;
position: absolute; 
display: block;  
}
.dialogs-elements-footer {
height: 48px;
width: 400px;
margin-top: 54px;
margin-left: 33px;
}

.dialog-cancel-button{
  height: 16px;
  width: 121px;
  color: #414C82;
  font-family: "Open Sans";
  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
  background-color: #FFFFFF;
}

.dialog-cancel-button:focus{
outline: none;
}

.dialog-submit-button
{
height: 37px;
width: 240px;
font-family: "Open Sans";
font-size: 14px;
font-weight: 600;
letter-spacing: 2.25px;
line-height: 16px;
text-align: center;
color: #FFFFFF;
box-sizing: border-box;
border: 1px solid #414C82;
background-color: #414C82;
margin-top: 2px;
}

.toast-body {
  color: rgba(0,0,0,0.87);
  font-family: "Open Sans";
  letter-spacing: 0.25px;
}

.toast-body-text {
  padding-left: 20px;
  font-size: 14px;
  color: black;
}

.toast-container {
color: black;
}

.toast-body-icon {
  padding-left: 10px;
  font-size: 15px;
}

.submiticon{
font-size: 20px;
margin-right: 5px;
vertical-align: middle;
}

@media only screen and (max-width: 1200px) { 
  .submit-desc-title{
    margin-top: -5px;
    margin-left: -20px;
  }
  .submit-button{
    margin-top: 10px;
  }
}


@media (min-width:1024px) and (max-width: 1200px) { 
.homeworkDetail-page {
  background-size:40%;
}
.attachment-section{
  margin-top: -22px;
}
}

@media (min-width:768px) and (max-width: 1024px) { 
.homeworkDetail-page {
  background-size:60%;
}
.attachment-section{
  margin-top: -22px;
}
}

@media (min-width:480px) and (max-width: 768px) { 
.submit-desc-title{
  font-size: 17px;
  margin-left: -20px;
}
.submit-button{
  font-size: 17px;
  margin-top: 10px;
}
.submit-title{
  height: 24px;
  width: 560px;
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
}
}


@media (min-width:320px) and (max-width: 480px) { 
.submit-desc-title{
  font-size: 17px;
}
.submit-button{
  font-size: 14px;
  margin-top: 10px;
}
.submit-title{
  height: 24px;
  width: 560px;
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
}
.buttons-outlined-with-icon-enable {
  height: 37px;
  width: 240px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #414C82;
  background-color: #414C82;
}

.buttons-outlined-with-icon-disable {
  height: 37px;
  width: 240px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 16px; 
  text-align: center; 
  color: rgba(0,0,0,0.38);
  box-sizing: border-box;
  background-color: #E4E4E4;
}

.submit-button-text{
  margin-left: 6px;
}

.submiticon{
  margin-left: -20px;
}
}