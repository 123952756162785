.file-size {
  height: 16px;
  width: 228px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Open Sans";
  font-size: 11.85px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.file-icons {
  height: 34px;
  width: 30px;
}
.file-name {
  height: 16px;
  width: 228px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Open Sans";
  font-size: 11.85px;
  letter-spacing: 0.4px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.homework-file-list-item {
  margin-top: 5px;
  line-height: 18px;
  padding: 8px 10px;
  cursor: pointer;
}
.homework-file-list-item-icon {
  width: 21px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  cursor: pointer;
  margin-top: 4px;
}
.attachment-item {
  width: 273px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 23px;
}
.attachment-none {
  height: 28px;
  width: 272px;
  color: rgba(0,0,0,0.87);
  font-family: "Open Sans";
  font-size: 15.8px;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-top: 2%;
}
.attachment-icon {
  padding-right: 5%;
}
.button-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}