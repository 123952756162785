.attachment-info {
  background-color: #fff;
  padding: 15px;
  display: table;
  width: 100%;
  margin: 1px 0;
  position: relative;
  text-align: left;
}

.attachment-info-file-size {
  position: absolute;
  bottom: 15px;
  height: 16px;
  width: 228px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Open Sans";
  font-size: 11.85px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

[tabindex="0"]:focus {
  outline: 0 !important;
}

.attachement-icon-row {
  display: table-row;
}

.attachment-info-form {
  display: table-cell;
  padding-left: 16px;
}

.attachement-icon {
  display: table-cell;
  vertical-align: top;
  position: relative;
  height: 32px;
  width: 28px;
}

.attachement-icon-percentage-text {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 13px;
  color: #000;
  text-shadow: 1px -1px 7px #fff, 1px -1px 17px #fff;
  margin-left: 3px;
}

.attachement-icon-percentage-text span {
  font-size: 10px;
}

.attachement-icon-percentage-overlay {
  position: absolute;
  background-color: #555;
  opacity: 0.3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.attachment-title {
  height: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Open Sans";
  font-size: 11.85px;
  letter-spacing: 0.4px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete-div {
  position: absolute;
  right: 10px;
  background-color: #ffffff !important;
}
.delete-button {
  box-sizing: border-box;
  height: 36px;
  width: 136px;
  border: 1px solid #414c82;
  background-color: #ffffff !important;
}
.delete-icon {
  height: 32px;
  width: 34px;
}

ion-button.delete-button.ion-color.ion-color-light {
    --ion-color-base: white !important;
    --border-radius:  0px !important;
    text-transform: uppercase !important;

}
.info-width{
  width: 40%;
  height: 1px;
}

@media (min-width:320px) and (max-width: 480px) { 
  .delete-button {
    box-sizing: border-box;
    border: 1px solid #414c82;
    background-color: #ffffff !important;
    height: 28px;
    font-size: 6px;
    width: 90px;
  }  
  .info-width{
    width: 65px;
    height: 1px;
  }
}
