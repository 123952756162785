.attachment-container {
  background-color: rgba(217, 255, 254, 0.5);
  min-height: 100px;
  padding-bottom: 48px;
  text-align: center;
  position: relative;
  padding: 5px 0;
  padding-bottom: 15px;
  box-shadow: inset 1px 1px 2px 0 rgba(65,76,130,0.25);
}


.attachment-container-scroll-container {
  padding: 0 7px;
  max-height: 260px;
  overflow-y: auto;
}

.attachment-container .upload-attachment {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.surface {
  box-sizing: border-box;
  height: 36px;
  width: 172px;
  border: 1px solid #414c82;
  background-color: #ffffff;
}

.button-text {
  height: 16px;
  width: 116px;
  color: #02083c;
  font-family: "Open Sans";
  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-align: center;
}
.attach-icon {
  height: 19px;
  width: 22px;
}

.attachment-container-text {
  margin-top: 17px;
  color: #999;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Open Sans";
  font-size: 15.8px;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: center;
}

.dragActive .attachment-container {
  background-color: #dedede !important;
}

ion-button.surface.ion-color.ion-color-light {
    --ion-color-base: white !important;
    --border-radius:  0px !important;
    text-transform: uppercase !important;
  }

