.field-validation-message{
    margin-bottom: 0px;
    display: inline-block;
    color: rgba(232, 17, 35, 1);
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:100%;
}
.validation-field{
    margin-top: 20px;
    width:100%;
}
.field-validation-message:first-child{
    margin-left: 0;
}

.field-validation-message:first-of-type{
    margin-left: 0;
}

.alert{
    color: rgba(184, 14, 28, 1);
    vertical-align: bottom;
    font-size: 18px;
}
.message{
    vertical-align: top;
}