.body-1 {
  font-family: "Open Sans";
  letter-spacing: 0.5px;
  line-height: 28px;
}

.body-2 {
  color: rgba(0,0,0,0.87);
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.headline-4 {
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 41px;
}

.headline-5 {
  font-family: Montserrat;
  font-weight: 600;
  line-height: 29px;
}

.headline-6 {
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.ion-color-header {
  --ion-color-base: var(--ion-color-header);
  --ion-color-base-rgb: var(--ion-color-header-rgb);
  --ion-color-contrast: var(--ion-color-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-header-contrast-rgb);
  --ion-color-shade: var(--ion-color-header-shade);
  --ion-color-tint: var(--ion-color-header-tint);
}

.header{
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
}

.homework-header{
  margin-left: 30px;
}

.homeworkDetail-page {
  background-image: var(--ion-content-background-img);
  background-size: 30%;
}

.homework-title{
    letter-spacing: 0.25px;
    margin-top: 40px;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
}

.homework-error-title{
  letter-spacing: 0.25px;
    margin-top: 70px;
    margin-bottom: -10px;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
}

.homework-info{
  text-align: center;
  margin-top: 10px;
  letter-spacing: 0.5px;
  padding:0px;
}
.homeworkdetailcard{
  background-color: white;
}

.errorcard{
  margin-top: 60px;
}

.homework-desc-title{
  font-size: 20px;
  margin-top: 10px;
  line-height: 1;
}

.homework-submit-title{
  font-size: 20px;
  line-height: 1;
}

.homework-error-desc{
  margin-top: 50px;
  height: 270px;
}

.homework-divider{
  margin-left: -15px;
}

.homework-description{
  letter-spacing: 0.5px;
  font-size: 16px;
  margin-bottom: 20px;
  white-space: pre-line;
  max-width: 100%;
 }
 
 .homework-setby{
  letter-spacing: 0.5px;
   font-size: 12px;
   margin-top: 10px;
   font-family: "Open Sans";
   line-height: 16px;
  }
 
.setby-usericon{
 font-size: 20px;
 margin-right: 5px;
 vertical-align: middle;
  }

.homework-setby ul { padding: 0px; }
ul li { display: inline; white-space: nowrap; }
ul li:after {font-size: 25px; content: " \2022";vertical-align: middle;margin-left: -2px; }
ul li.nobullet:after { content: none; }

.homework-status{
  margin-top: 12px;
  padding: 20px;
  color: white;
  text-align: center;
  font-size: 24px;
  background-color:#282D59;
}

.dateFormat{
  font-family: "Open sans";
  display: inline-flex;
  margin-left: -12px;
}
.attachment-heading{
  height: 24px;
  width: 272px;
  color: rgba(0,0,0,0.87);
  font-family: Montserrat;
  font-size: 19.79px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
}
.attachment-section{
  margin-top: 10%
}
.file-icon-style{
  height: 34px;
  width:30px;
}
.invalid{
  text-align: center;
  font-size: 24px;
  padding-top:10%;
}
.in-person{
  padding: 20px;
  text-align: center;
  background-color: #414C82;
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 13.7px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 16px;
}
.submit-section{
  padding-top: 20px;
}


.errorpageContent{
  margin-top: 30px;
  font-size: 24px;
}

.sticky-header{
  margin-top: 5px;
  position: sticky;
  z-index: 100000;
  background-color: #FFFFFF;
}

.header-divider {
  opacity: 0.12;
  height: 2px;
  background-color: rgba(0,0,0,0.87);
}

.notify-submit{
  margin: 0px 0px 5px 30px;
}

@media only screen and (min-width: 1200px) { 
  .hide-largescreen {
    display: none;
  }
 
}

@media only screen and (max-width: 1200px) { 
  .hide-smallscreen {
    display: none;
  }
  .homework-title{
    margin-top: 20px; 
    margin-bottom: 10px;
   }
   .homework-info{
     margin-top: -10px;
   }
   .homeworkdetailcard{
     margin-top: -20px;
   }
   .homework-desc-title{
     margin-top: -5px;
   }
   .homework-submit-title {
    margin-top: 0px;
   }
}


@media (min-width:1024px) and (max-width: 1200px) { 
  .homeworkDetail-page {
    background-size:40%;
  }
  .attachment-section{
    margin-top: -22px;
  }
}

@media (min-width:768px) and (max-width: 1024px) { 
  .homeworkDetail-page {
    background-size:60%;
  }
  .attachment-section{
    margin-top: -22px;
  }
}

@media (min-width:480px) and (max-width: 768px) { 
  .homeworkDetail-page {
    background-size:80%;
  }
  .homework-title{
    font-size: 26px;
   margin-top: 20px; 
   margin-bottom: 15px;
  }
  .homework-desc-title{
    font-size: 16px;
  }
  .homework-description{
    font-size: 16px;
  }
  .attachment-section{
    margin-top: -22px;
  }
  .errorcard {
    margin-top: 25px;
   }

   .body-2{
     font-size: 12px;
   }

   .homework-info{
    margin-top: -25px;
     font-size: 12px;
   }

   .homework-setby{
     font-size: 12px;
   }

}


@media (min-width:320px) and (max-width: 480px) { 
  .homeworkDetail-page {
    background-size: 120%;
  }
  .homework-title{
    font-size: 24px;
    margin-top: 20px; 
    margin-bottom: 15px;
   }

   .homework-info{
    margin-top: -25px;
    font-size: 11.85px;
  }

  .homework-desc-title{
    font-size: 14px;
  }
  .homework-description{
    font-size: 15.87px;
  }
  .homework-setby{
    font-size: 11.85px;
  }
  .attachment-section{
    margin-top: -22px;
  }
  .homework-error-desc {
    margin-top: 20px;
    height: 240px;
  }

  .homework-error-title{
    font-size: 28px;
  }
  .errorcard {
    margin-top: 25px;
  }
  .body-2{
    font-size: 13px;
  }
  .dateFormatSm{
    margin-left: -5px;
  }
}
